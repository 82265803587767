
import { Component, Vue, Watch } from "vue-property-decorator";
import { Route } from "vue-router";
import { TaskInterface } from "@/interfaces/task";
import { VehicleInterface } from "@/interfaces/vehicle";
import Vehicle = VehicleInterface.Vehicle;
import TheOrderModal from "@/components/order/TheOrderModal.vue";
import ModalStock from "@/components/_modal/ModalStock.vue";
import ModalDownload from "@/components/_modal/ModalDownload.vue";
import ModalProcess from "@/components/_modal/ModalProcess.vue";
import FilterEcu from "@/components/_inner/FilterEcu.vue";
import LoadFile from "@/components/_inner/LoadFile.vue";
import NoteWarning from "@/components/_tools/NoteWarning.vue";
import NoteError from "@/components/_tools/NoteError.vue";
import Patches from "@/components/_inner/Patches.vue";
import Dtc from "@/components/_inner/Dtc.vue";
import storage from "@/storage";
import api from "@/api";
import getCurrencySymbol from "@/additionally/getCurrencySymbol";
import getLangIdObject from "@/additionally/getLangIdObject";
import ResultPanel from "@/components/_inner/ResultPanel.vue";
import ModalMmcThanks from "@/components/_modal/ModalMmcThanks.vue";
import ModalMmcError from "@/components/_modal/ModalMmcError.vue";

type Payback = {
  done: Function;
};

@Component<OrderPage>({
  beforeRouteEnter(to: Route, from: Route, next: Function) {
    next((vm: OrderPage) => {
      if (
          from.name === "Prices" ||
          from.name === "Login" ||
          from.name === "Home"
      ) {
        vm.getLastVehicle();
        // блокируем фильтр
      }
    });
  },
  components: {
    ModalMmcError,
    ModalMmcThanks,
    TheOrderModal,
    ModalStock,
    ModalDownload,
    ModalProcess,
    FilterEcu,
    LoadFile,
    NoteWarning,
    NoteError,
    Patches,
    Dtc,
    ResultPanel,
  },
  metaInfo() {
    return {
      title: this.$tc("caption"),
    };
  },
})
export default class OrderPage extends Vue {
  showPayModal = false;
  showDownloadModal = false;
  showProcessModal = false;
  error = false;
  startFilterParams = {};
  task: TaskInterface.Task | null = null;
  fileId = 0;
  promoCode = "";
  currencyId = 1;
  dtcBillingDescription = "";
  dtcBillingNote = "";
  promoDescription = "";
  downloadToken = "";
  filterEmpty = true;
  isEmptyForm = true;
  timerstop = false;
  isedit = false;
  readOnly = true;
  isShowProcessPayment = false;
  isProdamusError = false;
  isProdamusSuccess = false;
  isShowModalError = false;

  @Watch("$i18n.locale")
  onWatchLocales() {
    this.addTitle();
    this.getBillingInfo();
  }

  getDescriptionData() {
    return this.task?.subscription;
  }

  addTitle() {
    ////document.title = this.$tc('caption')
  }

  checkEmptyForm(task: any) {
    function isEmptyForm(task: any) {
      if (
          task?.patches.find((item: any) => item.isSelected) ||
          task?.taskDtcCurrent.items.find((item: any) => item.isSel)
      ) {
        return false;
      }

      return true;
    }

    this.isEmptyForm = isEmptyForm(task);
  }

  getBillingInfo() {
    if (this.task) {
      const billingDescriptions: TaskInterface.BillingDescriptions = getLangIdObject<TaskInterface.BillingDescriptions>(this.task.billingDescriptions);

      this.dtcBillingDescription = billingDescriptions.tdcBillingDescription;
      this.dtcBillingNote = billingDescriptions.tdcBillingNote;
      this.promoDescription = billingDescriptions.promoDescription;
    }
  }

  getLastVehicle() {
    const vehicle = storage.lastVehicle;
    if (!vehicle) return;
    this.getStartFilterParams(vehicle);
    storage.lastVehicle = null;
  }

  getCurrencyId() {
    const id = storage.user?.currencyId;
    if (id) this.currencyId = id;
  }

  getStartFilterParams(vehicle: Vehicle) {
    this.startFilterParams = {
      typeId: vehicle.type.id,
      brandId: vehicle.brand.id,
      modelId: vehicle.model.id,
      engineId: vehicle.engine.id,
      ecuId: vehicle.ecu.id,
      readOnly: true,
    };
  }

  async createTask(data: { completed: boolean; vehicle: Vehicle }) {
    //console.log('createTask')
    this.error = false;
    this.filterEmpty = !data.completed;
    //if (!data.completed) return (this.task = null);
    if (!data.completed) return;
    if (this.fileId > 0)
      this.showProcessModal = true
    const task = await api.task.edit({
      vehicle: data.vehicle,
      fileId: this.fileId,
    });

    const timer = setInterval(() => {
      if (this.timerstop)
      {
        this.showProcessModal = false
        this.timerstop = false
      } else {
        clearInterval(timer)
      }
    }, 1000)

    this.timerstop = true

    //console.log(task)
    if (!task) return (this.error = true);
    if (this.fileId > 0) {
      //console.log('task.vehicle', task.vehicle)
      // @ts-expect-error
      // eslint-disable-next-line
      this.$refs.myFilter.setAnotherEcu(task.vehicle)
    }
    this.refreshTask(task);
    await this.calcPrice(task);
  }

  eventLoadFile(id: number) {
    this.error = false;
    this.fileId = id;
    this.fetchEditTask();
  }

  async fetchEditTask() {
    console.log(this.task)
    if (!this.task || this.filterEmpty) return;
    this.showProcessModal = true
    const task = await api.task.edit({ ...this.task, fileId: this.fileId });
    const timer = setInterval(() => {
      if (this.timerstop) {
        this.showProcessModal = false
        this.timerstop = false
      } else {
        clearInterval(timer)
      }
    }, 1000)
    this.timerstop = true
    if (!task) return (this.error = true);
    //task.patches = patches //slam
    // @ts-expect-error
    // eslint-disable-next-line
    this.$refs.myFilter.setAnotherEcu(task.vehicle)
    this.refreshTask(task); //slam
    await this.calcPrice(task);
  }

  async calcPrice(task = this.task) {
    this.checkEmptyForm(task);

    if (!task) return;
    const newTask = await api.task.calcPriceV2({
      ...task,
      fileId: this.fileId,
      promoCode: this.promoCode,
      currencyId: this.currencyId,
    });
    this.refreshTask(newTask);
  }

  refreshTask(task: TaskInterface.Task | null) {
    if (!task) return (this.error = true);
    this.task = task;
    storage.lastTaskVehicle = task ? task.vehicle : null;
    this.getBillingInfo();
  }

  refreshPromoCode(code: string) {
    this.promoCode = code;
    this.calcPrice();
  }

  async eventSaveAndOpenModal() {
    const isAuth = await api.authorize.isAuthenticated();
    if (!isAuth)
      return this.$router.push({
        name: "Login",
        params: { lang: this.$route.params.lang },
      });
    const saved = await this.saveTask();
    //if (saved) console.log(saved);
    if (saved) return this.openPayModal();
    alert("Error save task");
  }

  async saveTask(
      payback: Payback = {
        done: () => {
          // console.log(
          //   "typescript + vue2 = вот такие вот костыли приходиться писать, лень разбираться как это чинить"
          // );
        },
      }
  ): Promise<boolean> {
    if (!this.task) return false;
    const task = await api.task.save({
      ...this.task,
      fileId: this.fileId,
      promoCode: this.promoCode,
    });
    this.refreshTask(task);

    this.$nextTick(() => {
      payback.done();
    });

    return !!task;
  }

  openPayModal() {
    this.showPayModal = true;
  }

  closePayModal() {
    //console.log('closePayModal')
    //// @ts-expect-error
    //// eslint-disable-next-line
    //Robokassa.ClosePaymentForm();
    this.showPayModal = false;
    this.closeProcessPayment()
    this.openDownloadModal();
  }

  async openDownloadModal() {
    if (!this.task) return;
    const status = await api.payment.getTaskStatus(this.task.taskId);
    //console.log('total= '+this.total)
    if (status !== "Paid") return;
    //console.log(status)
    const token: string | null = await api.task.getDownloadLink(
        this.task.taskId
    );
    if (!token) return;
    this.downloadToken = token;
    this.showDownloadModal = true;
  }

  showProcessPayment () {
    this.isShowProcessPayment = true
  }

  closeProcessPayment () {
    this.isShowProcessPayment = false
  }

  showModalError() {
    this.isShowModalError = true
  }

  closeModalError() {
    this.isShowModalError = false
  }

  eventError () {
    this.showModalError()
    this.closePayModal()
    this.closeProcessPayment()
  }

  closeDownloadModal() {
    this.showDownloadModal = false;
    this.$router.push({
      name: "History",
      params: { lang: this.$route.params.lang },
    });
  }

  get total(): number {
    return this.price - this.discount;
  }

  get price(): number {
    let sum = 0;
    if (!this.task) return sum;
    if (this.task.patchesTotalPriceSum) sum += this.task.patchesTotalPriceSum;
    if (this.task.taskDtcCurrentTotalPriceSum)
      sum += this.task.taskDtcCurrentTotalPriceSum;
    return sum;
  }

  get discount(): number {
    let sum = 0;
    if (!this.task) return sum;
    if (this.task.patchesTotalDiscountSum)
      sum += this.task.patchesTotalDiscountSum;
    if (this.task.taskDtcCurrentTotalDiscountSum)
      sum += this.task.taskDtcCurrentTotalDiscountSum;
    return sum;
  }

  get currency(): string {
    if (!this.task) return "";
    return getCurrencySymbol(this.task.currencyId);
  }

  created() {
    this.addTitle();
    this.getBillingInfo();
  }

  mounted() {
    this.getCurrencyId();
    this.checkEmptyForm(this.task);
  }
}
