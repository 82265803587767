
import '@/assets/styles/imports/load-file.sass'
import { Component, Vue } from 'vue-property-decorator'
import api from '@/api'

@Component
export default class LoadFile extends Vue {
  fileName = ''
  drag = false
  done = false
  progress = '0%'

  // eslint-disable-next-line
  eventLoadFile (event: any) {
    if (!event.target.files.length) return
    const file = event.target.files[0]
    this.loadFile(file)
  }

  // eslint-disable-next-line
  eventDrop (event: any) {
    event.stopPropagation()
    const file = event.dataTransfer.files[0]
    this.loadFile(file)
    this.drag = false
  }

  async loadFile (file: File) {
    const response = await api.firmware.uploadUserFile(file, this.loading)
    if (response.error) {
      this.done = false
      alert(response.error)
    } else {
      this.done = true
      this.fileName = file.name
    }
    this.eventGetFileId(response.id)
  }

  loading (event: ProgressEvent) {
    this.progress = `${Math.floor(event.loaded / event.total * 100)}%`
  }

  eventStop (event: Event) {
    event.stopPropagation()
  }

  eventDragleave () {
    this.drag = false
  }

  eventGetFileId (id: number) {
    this.$emit('get-file-id', id)
  }

  // eslint-disable-next-line
  eventWindowDragEnter (event: any) {
    event.preventDefault()
    if (event.dataTransfer && event.dataTransfer.types[0] !== 'Files') return
    this.drag = true
  }

  mounted () {
    window.addEventListener('dragenter', this.eventWindowDragEnter)
  }

  destroyed () {
    window.removeEventListener('dragenter', this.eventWindowDragEnter)
  }
}
