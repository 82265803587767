
import '@/assets/styles/imports/order-modal.sass'
import { Component, Vue } from 'vue-property-decorator'
import storage from "@/storage"
import api from '@/api'

@Component
export default class TheOrderModal extends Vue {
  hideModal = false
  email = ''
  resend = false

  close () {
    this.$router.push({ name: 'History', params: { lang: this.$route.params.lang }})
  }

  async resendEmail () {
    if (this.resend) return
    this.resend = true
    await api.authorize.verifyEmail()
  }

  mounted () {
    this.email = storage.user?.email || 'email'
    this.hideModal = storage.user?.emailConfirmed || false
  }
}
